body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  color: white;
}

div {
  text-align: center;
}

div.linkedin {
  position: relative;
  background-image:url(/src/img/linkedin.png);
  background-size: cover;
  width: 13px;
  height: 13px;
  left: 53px;
  top: 14px;
  color: white;
}

